import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { api } from './api.reducer';
import { authentication } from './authentication.reducer';

const rootReducer = combineReducers({
  alert,
  api,
  authentication,
});

export default rootReducer;
