import { userConstants } from '../constants';
import { authenticationService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

export const userActions = {
  login,
  logout,
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    authenticationService.login(username, password)
      .then(
        data => {
          dispatch(success(data.user));
          history.push('/');
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  authenticationService.logout();
  return { type: userConstants.LOGOUT };
}
