import moment from 'moment';

import { store } from '../helpers';
import { handleResponse } from '../helpers';
import { authHeader } from '../helpers';

export const usageService = {
  list,
};

function list(query) {
  const apiUrl = store.getState().api.apiUrl;
  let url = apiUrl + '/usages' +
    '?code=' + query.code +
    '&caption=' + query.caption +
    '&fromDate=' + moment(query.fromDate).toISOString() +
    '&toDate=' + moment(query.toDate).toISOString() +
    '&limit=' + query.pageSize +
    '&page=' + (query.page + 1) +
    '&sort=' + (query.orderBy ? query.orderBy.field.replace('client', 'Clients') : 'connect_time') +
    '&direction=' + (query.orderDirection || 'desc');
  return fetch(url, {
    headers: authHeader(),
  })
    .then(handleResponse)
    .then(result => {
      return {
        data: result.data,
        page: result.page - 1,
        totalCount: result.total,
      }
    })
}
