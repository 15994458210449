import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import { authHeader } from '../helpers';
import { clientService } from '../services';

const useStyles = makeStyles((theme) => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonRow: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const AddClient = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [caption, setCaption] = useState('');

  const addClient = () => {
    clientService.add({ code, caption });
    props.handleClose(true);
  }

  return (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">Add Client</h2>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <TextField id="outlined-basic" label="Code" variant="outlined" value={code} onChange={(e) => setCode(e.target.value)} />
        </Grid>
        <Grid item xs={6}>
          <TextField id="outlined-basic" label="Client" variant="outlined" value={caption} onChange={(e) => setCaption(e.target.value)} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} className={classes.buttonRow}>
          <Button variant="contained" color="primary" onClick={addClient}>
            Add
          </Button>
          <Button variant="contained" onClick={() => props.handleClose()}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  )
});

function Client() {
  const apiUrl = useSelector(state => state.api.apiUrl);
  const [open, setOpen] = useState(false);
  const tableRef = useRef();

  if (!apiUrl) {
    return '';
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (isRefresh = false) => {
    if (isRefresh) {
      tableRef.current.onQueryChange();
    }
    setOpen(false);
  };

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        title="Client List"
        columns={[
          { title: 'Id', field: 'id', editable: 'never' },
          { title: 'Code', field: 'code', editable: 'never' },
          { title: 'Client', field: 'caption', editable: 'always' },
        ]}
        editable={{
          onRowUpdate: (newData, oldData) => {
            return fetch(apiUrl + '/clients/' + oldData.id, {
              method: 'PUT',
              headers: authHeader(),
              body: JSON.stringify({ caption: newData.caption })
            })
          }
        }}
        options={{
          actionsColumnIndex: -1
        }}
        data={clientService.list}
        actions={[
          {
            icon: 'add',
            tooltip: 'Add Client',
            isFreeAction: true,
            onClick: handleOpen
          }
        ]}
      />
      <Modal
        open={open}
        onClose={handleClose}
      >
        <AddClient handleClose={handleClose} />
      </Modal>
    </>
  )
}

export default Client;
