import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import Moment from 'react-moment';

import { authHeader } from '../helpers';
import { handleResponse } from '../helpers';
import { authenticationService } from '../services';

function Online() {
  const [data, setData] = useState([]);
  const apiUrl = useSelector(state => state.api.apiUrl);
  var timerID = useRef(null);

  useEffect(() => {
    const updateData = () => {
      fetch(apiUrl + '/clients/online', {
        headers: authHeader()
      })
        .then(handleResponse)
        .then(
          (result) => {
            setData(result.data);
          },
          (error) => {
            console.log('fetch error');
            console.log(error);
            authenticationService.logout();
            window.location.reload(true);
          }
        )
    };
    if (apiUrl) {
      updateData();
      timerID.current = setInterval(
        () => updateData(),
        10000
      );
    }
    return () => {
      clearInterval(timerID.current);
    }
  }, [apiUrl]);

  return (
    <MaterialTable
      title={'Online ' + moment.unix(data.now).format('YYYY-MM-DD HH:mm:ss')}
      columns={[
        { title: 'Client', field: 'name' },
        { title: 'Code', field: 'code' },
        { title: 'Public IP', field: 'real_address' },
        { title: 'Private IP', field: 'virtual_address' },
        { title: 'Connected Since', field: 'connected_since', type: 'datetime', defaultSort: 'asc',
          render: rowData => <Moment unix format="YYYY-MM-DD HH:mm:ss">{rowData.connected_since}</Moment>
        },
        { title: 'Session Time', type: 'numeric', render: rowData => {
          let duration = data.now - rowData.connected_since
          return  <>
            { duration >= 86400 && (Math.floor(duration / 86400) + 'd ') }
            <Moment unix utc format={(duration >= 86400 ? 'H' : '') + "H:mm:ss"}>{duration * 1000}</Moment>
          </>
        } },
        { title: 'Upload (byte)', field: 'bytes_received', type: 'numeric', render: rowData => rowData.bytes_received.toLocaleString() },
        { title: 'Download (byte)', field: 'bytes_sent', type: 'numeric', render: rowData => rowData.bytes_sent.toLocaleString() },
      ]}
      data={data.client}
      options={{
        padding: 'dense',
        paging: false,
        search: false,
        sorting: true
      }}
    />
  );
}

export default Online;
