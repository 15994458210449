import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Router,
  Link,
  Switch,
  Route
} from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HttpsIcon from '@material-ui/icons/Https';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import MomentUtils from '@date-io/moment';

import { alertActions, apiActions, userActions } from './actions';
import { history } from './helpers';
import PrivateRoute from './components/PrivateRoute';
import Client from './components/Client';
import Login from './components/Login';
import Online from './components/Online';
import Usage from './components/Usage';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert);
  const title = useSelector(state => state.api.title);
  let user = useSelector(state => state.authentication.user);

  useEffect(() => {
    fetch('config.json')
      .then(res => res.json())
      .then((result) => {
        dispatch(apiActions.setUrl(result.apiUrl));
        dispatch(apiActions.setTitle(result.title));
        document.title = result.title;
      });
  }, [dispatch]);

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAlertClose = () => {
    dispatch(alertActions.clear());
  };

  const logout = () => {
    dispatch(userActions.logout());
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap className={classes.title}>
                {title || 'VPN Status'}
              </Typography>
              {user && user.token &&
                <Button color="inherit" onClick={logout}>Logout</Button>
              }
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button selected={history.location.pathname === '/'} component={Link} to="/">
                <ListItemIcon><HttpsIcon /></ListItemIcon>
                <ListItemText primary="Online" />
              </ListItem>
              <ListItem button selected={history.location.pathname === '/client'} component={Link} to="/client">
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary="Clients" />
              </ListItem>
              <ListItem button selected={history.location.pathname === '/usage'} component={Link} to="/usage">
                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                <ListItemText primary="Usages" />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {alert.message &&
              <Alert severity="Error" onClose={handleAlertClose}>{alert.message}</Alert>
            }
            <Switch>
              <Route path="/login" component={Login} />
              <PrivateRoute path="/client" component={Client} />
              <PrivateRoute path="/usage" component={Usage} />
              <PrivateRoute exact path="/" component={Online} />
            </Switch>
          </main>
        </div>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default App;
