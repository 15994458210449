import { store } from '../helpers';
import { handleResponse } from '../helpers';

export const authenticationService = {
  login,
  logout,
};

function login(username, password) {
  const apiUrl = store.getState().api.apiUrl;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
    body: JSON.stringify({ username, password })
  };

  return fetch(apiUrl + '/users/login', requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user.user));

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}
