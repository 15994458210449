import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Moment from 'react-moment';

import { usageService } from '../services';

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function Usage() {
  const classes = useStyles();
  const today = moment().startOf('day');
  const [code, setCode] = useState('');
  const [caption, setCaption] = useState('');
  const [fromDate, setFromDate] = useState(today.valueOf());
  const [toDate, setToDate] = useState(today.valueOf());
  const tableRef = useRef();
  const apiUrl = useSelector(state => state.api.apiUrl);

  if (!apiUrl) {
    return '';
  }

  return (
    <>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="YYYY-MM-DD"
            margin="normal"
            autoOk={true}
            id="date-picker-inline"
            label="From date"
            value={fromDate}
            onChange={setFromDate}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            format="YYYY-MM-DD"
            margin="normal"
            autoOk={true}
            id="date-picker-inline"
            label="To date"
            value={toDate}
            onChange={setToDate}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <TextField id="outlined-basic" label="Code" variant="outlined" value={code} onChange={(e) => setCode(e.target.value)} />
        </Grid>
        <Grid item xs={6}>
          <TextField id="outlined-basic" label="Client" variant="outlined" value={caption} onChange={(e) => setCaption(e.target.value)} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} className={classes.buttonRow}>
          <Button variant="contained" color="primary" onClick={() => tableRef.current.onQueryChange()}>
            Search
          </Button>
        </Grid>
      </Grid>
      <MaterialTable
        tableRef={tableRef}
        title="Usage List"
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Client', field: 'client.caption' },
          { title: 'Code', field: 'client.code' },
          { title: 'Public IP', field: 'ip_address' },
          { title: 'Connect time', field: 'connect_time' ,
            render: rowData => <Moment format="YYYY-MM-DD HH:mm:ss">{rowData.connect_time}</Moment>
          },
          { title: 'Disconnect time', field: 'disconnect_time',
            render: rowData => rowData.disconnect_time && <Moment format="YYYY-MM-DD HH:mm:ss">{rowData.disconnect_time}</Moment>
          },
          { title: 'Session Time', type: 'numeric', render: rowData => {
            if (!rowData.disconnect_time) {
              return '';
            }
            let duration = moment(rowData.disconnect_time).unix() - moment(rowData.connect_time).unix();
            return  <>
              {duration >= 86400 && (Math.floor(duration / 86400) + 'd ')}
              <Moment unix utc format={(duration >= 86400 ? 'H' : '') + "H:mm:ss"}>{duration * 1000}</Moment>
            </>
          } },
            { title: 'Upload (byte)', field: 'upload', type: 'numeric', render: rowData => rowData.upload && rowData.upload.toLocaleString() },
          { title: 'Download (byte)', field: 'download', type: 'numeric', render: rowData => rowData.download && rowData.download.toLocaleString() },
        ]}
        data={query => {
          query.code = code;
          query.caption = caption;
          query.fromDate = fromDate;
          query.toDate = toDate;
          return usageService.list(query);
        }}
        options={{
          padding: 'dense',
          pageSize: 20,
          search: false,
        }}
      />
    </>
  )
}

export default Usage;
