import { apiConstants } from '../constants';

export const apiActions = {
  setTitle,
  setUrl
}

function setTitle(title) {
  return {
    type: apiConstants.SET_TITLE,
    title
  }
}

function setUrl(url) {
  return {
    type: apiConstants.SET_URL,
    url
  };
}
