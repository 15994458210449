import { store } from '../helpers';
import { handleResponse } from '../helpers';
import { authHeader } from '../helpers';

export const clientService = {
  list,
  add,
};

function list(query) {
  const apiUrl = store.getState().api.apiUrl;
  let url = apiUrl + '/clients' +
    '?limit=' + query.pageSize +
    '&page=' + (query.page + 1) +
    '&sort=' + (query.orderBy ? query.orderBy.field : 'id') +
    '&direction=' + (query.orderDirection || 'asc');
  return fetch(url, {
    headers: authHeader(),
  })
    .then(handleResponse)
    .then(result => {
      return {
        data: result.data,
        page: result.page - 1,
        totalCount: result.total,
      }
    })
}

function add(query) {
  const apiUrl = store.getState().api.apiUrl;
  let url = apiUrl + '/clients';
  return fetch(url, {
    headers: authHeader(),
    method: 'POST',
    body: JSON.stringify({ code: query.code, caption: query.caption })
  })
    .then(handleResponse)
    .then(result => {
      return {
        data: result.data,
      }
    })
}
