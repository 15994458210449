import { apiConstants } from '../constants';

export function api(state = {}, action) {
  switch (action.type) {
    case apiConstants.SET_URL:
      return {
        ...state,
        apiUrl: action.url
      };
    case apiConstants.SET_TITLE:
      return {
        ...state,
        title: action.title
      };
    default:
      return state;
  }
}
